import './App.css';
import Titulo from './Componentes/Titulo';
import VentanaConDif from './Componentes/VentanaConDif';
import VentanaColorEntero from "./Componentes/VentanaColorEntero";
import VentanaCarrusel from "./Componentes/VentanaCarrusel"
import CarruselDoble from "./Componentes/CarruselDoble"
import ScrollLargo from "./Componentes/ScrollLargo"
import FotoReduccion from "./Componentes/FotoReduccion"
import CarruselAuto from "./Componentes/CarruselAuto"
import Footer from "./Componentes/Footer"
import NavBar from "./Componentes/NavBar"

import GridVentanas from "./Componentes/GridVentanas"
import iconoFlechaBoton from "./Img/arrow-right-s-line.png"

function App() {
  return (
    <div className="App" style={{ backgroundColor: "black", overflow: "hidden" }}>
      <NavBar />
      <div className='Titulo' >
        <Titulo />
      </div>
      <div className='BotonIniciar' >
        <a href='https://partners.landersteams.com/' className='ContenedorBoton'>
          <div className="TituloBoton">
              Inicia el cambio aquí
          </div>
          <div className='IconoBoton'>
            <img className='iconoFlechaDerecha'
              src={iconoFlechaBoton}
            ></img>
          </div>
        </a>
      </div>
      <div className='GridVentanas'>
        <GridVentanas />
      </div>

      <div className='CarruselDoble'>
        <CarruselDoble />
      </div>

      <div className='ScrollLargo'>
        <ScrollLargo />
      </div>
      <div className='FotoReduccion'>
        <FotoReduccion />
      </div>
      <div className='CarruselAuto'>
        <CarruselAuto />
      </div>
      <div className='Footer'>
        <Footer />
      </div>



      {/*
  <div className='GridVentanas' style={{
        marginBottom: "50px",
        maxWidth: '2000px',
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: "center"
      }}>
        <VentanaConDif
          titulo="Social Trends 2024"
          subtitulo="ENFOQUE"
          descripcion="Identifica las tendencias profundizando en la relación socioemocional de los humanos con la tecnología. Lo que necesita saber para afrontar este 2024."
          url="https://images.pexels.com/photos/931887/pexels-photo-931887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <VentanaColorEntero
          titulo="IA en Latinoamerica 2024"
          subtitulo="INFORME"
          descripcion="El potencial de la Inteligencia Artificial Generativa, sus aplicaciones comerciales y la reingeniería de procesos para potenciar su oferta."
        />
        <VentanaConDif
          titulo="Impacto del CGI en el Branding"
          subtitulo="ENFOQUE"
          descripcion="Las empresas logran mayor rendimiento gracias a la tecnología e innovación en la comunicación. El uso de modelación 3D e IA generativa maximiza el atractivo de la oferta."
          url="https://images.pexels.com/photos/7078717/pexels-photo-7078717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <VentanaCarrusel
          titulo="Mezclando átomos y bits en LATAM"
          subtitulo="ENFOQUE"
          descripcion="Las tendencias de tecnología 2024, identidad digital, IA generativa, datos y más. Siempre con un objetivo de mejorar la reinvencion y el crecimiento"
          url="https://www.reef2reef.com/media/teal-hammer.3131/full?d=1531970600"
        />
        <VentanaCarrusel
          titulo="Pilares del Cambio: Digitalización hacia el Metaverso"
          subtitulo="ENFOQUE"
          descripcion="El ritmo de disrupción actual inició un periodo de cambio más competitivo. Para triunfar en este entorno complejo y dinámico, los líderes deberán aprovechar los pilares del cambio."
          url="https://images.pexels.com/photos/1090977/pexels-photo-1090977.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <VentanaConDif
          titulo="Sector micro: Fronteras y mejora de rendimiento"
          subtitulo="ENFOQUE"
          descripcion="Qué están haciendo las organizaciones con mayor madurez operativa para crear valor de negocio de una forma más eficiente."
          url="https://images.pexels.com/photos/8938629/pexels-photo-8938629.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <VentanaConDif
          titulo="Sostenibilidad y transformación en el 2024"
          subtitulo="ENFOQUE"
          descripcion="Explora la forma en que los líderes pueden adoptar la sostenibilidad sin destruir el modelo tradicional de negocio."
          url="https://images.pexels.com/photos/7241432/pexels-photo-7241432.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <VentanaColorEntero
          titulo="Nueva fundacion especializada en servicios"
          subtitulo="ANNOUNCEMENT"
          descripcion="Un nuevo set de servicios cohesionados ayudara a las empresas a escalar sus esfuerzos a un maximo nivel en lo que conlleva a la un modelo de AI generativa conocida como switchboard"
        />


      </div>
  <div className='FotoReduccion'>
        <FotoReduccion />
      </div>
    */}




    </div>
  );
}

export default App;