import React, { useState } from 'react';
import './VentanaColorEntero.css';

const TuComponente = ({ titulo, subtitulo, descripcion, colorLetra, urlBlog }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={`mi-div-entero ${hovered ? 'hovered-entero' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{color: colorLetra}} className="contenido-entero">
        <div className='tituloContenido' style={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "135px",
          textAlign: "left",
          justifyContent: "space-around",
          alignItems: "center"
        }}>
          <div style={{
            position: "relative",
            width: "85%"
          
          }}>
            <h3 style={{ fontSize: "12px" }}>{subtitulo}</h3>
            <h2 style={{ fontSize: "20px" }}>{titulo}</h2>
          </div>
        </div>
        <div className={`descripcion-entero ${hovered ? 'visible-entero' : ''}`}>
          <div style={{
            color: colorLetra, 
            width: "85%", 
            textAlign: "left", 
            fontSize: "14px"
            }}>
            <p>{descripcion}</p>
          </div>
        </div>
        <a href={urlBlog} className={`expandir-entero ${hovered ? 'visible-entero' : ''}`}
        style={{
          color: colorLetra
        }}
        >Expandir</a>
      </div>
    </div>
  );
};

export default TuComponente;