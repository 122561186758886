import React, { useRef, useEffect, useState } from 'react';
import "./ScrollLargo.css"

const MiComponente = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const actualizarHeight = () => {
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', actualizarHeight);
        return () => {
            window.removeEventListener('resize', actualizarHeight);
        };
    }, []);

    const divPrincipalRef = useRef(null);
    const divStickyRef = useRef(null);
    const [sticky, setSticky] = useState(false);

    const handleMouseEnter = (event) => {
        const frasePrincipal = event.currentTarget.querySelector('.frasePrincipalDescripcionScroll');
        const fraseSecundaria = event.currentTarget.querySelector('.fraseSecundariaDescripcionScroll');
        const expandir = event.currentTarget.querySelector('.expandir');
        if (frasePrincipal) {
            frasePrincipal.style.top = '-100%'; // Cambia el valor según tu diseño
            fraseSecundaria.style.top = "0%"
            expandir.style.top = "70%"
        }
    };

    const handleMouseLeave = (event) => {
        const frasePrincipal = event.currentTarget.querySelector('.frasePrincipalDescripcionScroll');
        const fraseSecundaria = event.currentTarget.querySelector('.fraseSecundariaDescripcionScroll');
        const expandir = event.currentTarget.querySelector('.expandir');
        if (frasePrincipal) {
            frasePrincipal.style.top = '0%';
            fraseSecundaria.style.top = "100%";
            expandir.style.top = "140%"
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const divPrincipal = divPrincipalRef.current;
            const divSticky = divStickyRef.current;

            if (divPrincipal && divSticky) {
                const divPrincipalRect = divPrincipal.getBoundingClientRect();
                const divStickyRect = divSticky.getBoundingClientRect();

                if (
                    divPrincipalRect.bottom >= window.innerHeight &&
                    divPrincipalRect.top <= 0
                ) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='estiloDivPrincipal'
     
            ref={divPrincipalRef}
        >
            <div className='cuadroScroll1'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className='frasePrincipalDescripcionScroll'>
                    <div className='contenedorFraseDescripcionScroll'>
                        <p className='frasePrincipalScroll'>
                            Aseguramos cada proyecto
                        </p>
                        <p className='descripcionScroll'>
                            Innova sin límites. Nuestra garantía de resultados te respalda.
                        </p>
                    </div>
                </div>
                <div className='fraseSecundariaDescripcionScroll'>
                    <p className="fraseSecundariaScroll">
                        ¿El partner no funcionó?, ¿Buscabas algo diferente?
                        Reasigna tu proyecto sin costo adicional.
                    </p>
                </div>
                <a href='https://partners.landersteams.com/como-funciona/' className='expandir'>Expandir</a>
            </div>
            <div className='cuadroScroll2'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className='frasePrincipalDescripcionScroll'>
                    <div className='contenedorFraseDescripcionScroll'>
                        <p className='frasePrincipalScroll'>
                            Soporte en vivo
                        </p>
                        <p className='descripcionScroll'>
                            Accede a nuestra base de conocimientos
                        </p>
                    </div>
                </div>
                <div className='fraseSecundariaDescripcionScroll'>
                    <p className="fraseSecundariaScroll">
                        Atención vía chat en vivo por WhatsApp o Messenger.
                    </p>
                </div>
                <a href='https://wa.link/2n1anh' className='expandir'>Expandir</a>
            </div>
            <div className='cuadroScroll3'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className='frasePrincipalDescripcionScroll'>
                    <div className='contenedorFraseDescripcionScroll'>
                        <p className='frasePrincipalScroll'>
                            Partners verificados
                        </p>
                        <p className='descripcionScroll'>
                            Freelancers con experiencia y portafolios verificados.
                        </p>
                    </div>
                </div>
                <div className='fraseSecundariaDescripcionScroll'>
                    <p className="fraseSecundariaScroll">
                    Elige el perfil que mayor se adecue a su proyecto. Pagos por entrega.
                    </p>
                </div>
                <a href='https://partners.landersteams.com/buscar-afiliados/' className='expandir'>Expandir</a>
            </div>

            <div className='estiloDivSticky'
                ref={divStickyRef}
                style={{
                    position: sticky ? 'fixed' : 'absolute',
                }}
            >
                <div className='tituloSticky'>
                    <h1>Beneficios con soporte y garantía</h1>
                </div>
            </div>
            Contenido del div principal
            <div className='estiloDivEstatico'>
                <div className='tituloSticky'>
                    <h1>Beneficios con soporte y garantía</h1>
                </div>
            </div>
        </div>
    );
};

export default MiComponente;