import React, { useState } from 'react';
import './VentanaCarrusel.css';

const TuComponente = ({ titulo, subtitulo, descripcion, url, urlBlog,  colorLetra}) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div
            className={`mi-div-carrusel ${hovered ? 'hovered-carrusel' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div style={{color: colorLetra}} className="contenido-carrusel">
                <div className='tituloContenido' style={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    height: "135px",
                    textAlign: "left",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <div style={{
                        position: "relative",
                        width: "85%"
                    }}>
                        <h3 style={{ fontSize: "12px" }}>{subtitulo}</h3>
                        <h2 style={{ fontSize: "20px" }}>{titulo}</h2>
                    </div>
                </div>
                <div className={`descripcion-carrusel ${hovered ? 'visible-carrusel' : ''}`}>
                    <div style={{ 
                        width: "85%", 
                        textAlign: "left", 
                        fontSize: "14px",
                        color: colorLetra
                        }}>
                        <p>{descripcion}</p>
                    </div>
                </div>
                <a href={urlBlog} style={{color: colorLetra}} className={`expandir-carrusel ${hovered ? 'visible-carrusel' : ''}`}>Expandir</a>
                <div className={`imagen-carrusel ${hovered ? 'visible-carrusel' : ''}`}>
                    <img src={url}
                        width="275px"
                        height="215"
                    ></img>
                </div>
            </div>
        </div>
    );
};

export default TuComponente;