import React, { useState } from 'react';
import './CarruselDoble.css'; // Asegúrate de tener un archivo CSS para este componente
import iconoFlechaExpandir from "../Img/arrow-right-s-line.png"
const TuComponente = () => {
    const datos = [
        {
            url: 'https://images.pexels.com/photos/754102/pexels-photo-754102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            titulo: 'Aportar antes de Importar',
            descripcion: 'La forma de crecer cambió para siempre y en un mundo de constante evolución, solo queda adaptarse. ¿La solución? Una transformación a partir de la reingeniería y la automatización.',
            urlBlog: "https://peru.landersteams.com/aportar-antes-de-importar/"
        },
        {
            url: 'https://images.pexels.com/photos/17483910/pexels-photo-17483910/free-photo-of-abstracto-tecnologia-investigacion-digital.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            titulo: 'Pensamiento disruptivo para las operaciones locales y regionales',
            descripcion: 'El tratamiento de información con inteligencia artificial se esta volviendo un pilar a la hora de descentralizar las operaciones de las empresas del ahora. No es opción quedarse atrás',
            urlBlog:"https://peru.landersteams.com/pensamiento-disruptivo-para-las-operaciones-locales-y-regionales/"
        },
        {
            url: 'https://images.pexels.com/photos/5473955/pexels-photo-5473955.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            titulo: 'IA para todos',
            descripcion: "El crecimiento omnicanal usando IA generativa es una opción cada vez más accesible para todos. Sin embargo, estas herramientas en manos más experimentadas pueden lograr auténticos desafíos",
            urlBlog:"https://peru.landersteams.com/ia-para-todos/"
        },
    ];
    const [indiceActual, setIndiceActual] = useState(0);
    const [transicionSalida, setTransicionSalida] = useState(false);
    const [indiceResaltado, setIndiceResaltado] = useState(null);

    const cambiarImagen = (indice) => {
        setTransicionSalida(true);

        setTimeout(() => {
            setIndiceActual(indice);
            setIndiceResaltado(indice);
            setTransicionSalida(false);
        }, 500);
    };

    return (
        <div className='ComponenteCarruselDoble'>
            <div className='tituloCarruselDoble'>
                <div className="contenedorTituloSubtitulo">
                    <h1 className='titulo'>360° VALUE</h1>
                    <p className="subtitulo">En cada proyecto, abrimos paso al cambio y creamos valor para todos nuestros grupos de interés desde cualquier lugar del mundo.</p>
                </div>
            </div>

            <div className="contenedor">
                <div className={`divIzquierdo ${transicionSalida ? 'salida' : ''}`}>
                    <img className='imagenDivIzquierdo' src={datos[indiceActual].url} alt={datos[indiceActual].titulo}
                         />
                </div>
                <div className={`divDerecho ${transicionSalida ? 'salida' : ''}`}>
                    <div className='tituloDescripcionDerecho'>
                        <div className='tituloDerecho'>
                            <h2>{datos[indiceActual].titulo}</h2>
                        </div>
                        <div className='descripcionDerecho'>
                            <p>{datos[indiceActual].descripcion}</p>
                        </div>
                        <a href={datos[indiceActual].urlBlog} className='expandirFotoCarusel'>
                            <p className="expandirCarrusel" >
                                Expandir</p>
                            <div className='iconoExpandir'>
                                <img className='iconoFlechaExpandir' 
                                src={iconoFlechaExpandir}
                                style={{
                                    width:"100%",
                                    height:"100%"
                                }}
                                ></img>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            {/* Nuevo div debajo del contenedor */}
            <div className='controlador'>
                <div className="nuevoContenedor">
                    <div
                        className="iconoIzquierdo"
                        onClick={() =>
                            cambiarImagen((indiceActual - 1 + datos.length) % datos.length)
                        }
                    >
                        &#60;
                    </div>
                    <div
                        className="iconoDerecho"
                        onClick={() => cambiarImagen((indiceActual + 1) % datos.length)}
                    >
                        &#62;
                    </div>
                </div>

                {/* Nueva sección con una fila de divs */}
                <div className="filaDeDivs">
                    {datos.map((dato, index) => (
                        <div
                            key={index}
                            className={`divEnFila ${indiceResaltado === index ? 'resaltado' : ''}`}
                            onClick={() => cambiarImagen(index)}
                        >
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default TuComponente;