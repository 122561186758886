import React, { useState } from 'react';
import './VentanaConDif.css';

const TuComponente = ({ titulo, subtitulo, descripcion, url, urlBlog, colorLetra}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      style={{ 
        backgroundImage: `url(${url})`
      }}
      className={`mi-div ${hovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{color: colorLetra}} className="contenido">
        <div className='tituloContenido' style={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "135px",
          textAlign: "left",
          justifyContent: "space-around",
          alignItems: "center"
        }}>
          <div style={{
            position: "relative",
            width: "85%",
          }}>
            <h3 style={{ fontSize: "12px" }}>{subtitulo}</h3>
            <h2 style={{ fontSize: "20px" }}>{titulo}</h2>
          </div>
        </div>
        <div className={`descripcion ${hovered ? 'visible' : ''}`}>
          <div style={{
            width:"85%", 
            textAlign:"left", 
            fontSize:"14px",
            color: colorLetra
            }}>
            <p>{descripcion}</p>
          </div>
        </div>
        <a href={urlBlog} style={{color: colorLetra}} className={`expandirVentanaDif ${hovered ? 'visible' : ''}`}>Expandir</a>
      </div>
    </div>
  );
};

export default TuComponente;