import React, { useState } from 'react';
import './Titulo.css';

const Titulo = () => {
  // Estados para almacenar el ancho actual de los divs comodines
  const [comodinInicio1Width, setComodinInicio1Width] = useState(5);
  const [comodinFinal1Width, setComodinFinal1Width] = useState(5);
  const [comodinInicio2Width, setComodinInicio2Width] = useState(5);
  const [comodinFinal2Width, setComodinFinal2Width] = useState(5);

  // Manejador del evento onMouseMove
  const handleMouseMove = (event) => {
    // Obtener la dirección del movimiento del ratón
    const direction = event.movementX > 0 ? 'right' : 'left';

    // Actualizar los estados en función de la dirección del movimiento
    if (direction === 'right') {
      setComodinInicio1Width((prevWidth) => Math.min(prevWidth + 0.25, 5));
      setComodinFinal1Width((prevWidth) => Math.max(prevWidth - 0.25, 0));
      setComodinInicio2Width((prevWidth) => Math.max(prevWidth - 0.25, 0));
      setComodinFinal2Width((prevWidth) => Math.min(prevWidth + 0.25, 5));
    } else {
      setComodinInicio1Width((prevWidth) => Math.max(prevWidth - 0.25, 0));
      setComodinFinal1Width((prevWidth) => Math.min(prevWidth + 0.25, 5));
      setComodinInicio2Width((prevWidth) => Math.min(prevWidth + 0.25, 5));
      setComodinFinal2Width((prevWidth) => Math.max(prevWidth - 0.25, 0));
    }
  };

  return (
    <div
      className='Titulo'
      style={{
        display:"flex",
        justifyContent:"center",
        marginBottom:"5vh",
      }}
      onMouseMove={handleMouseMove}
    >
      <div className='contenedorFilas'>
        <div className='filaTitulo1' style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "50%",
        }}>
          <div className="comodinInicio1" style={{
            overflow: "hidden",
            height: "100%",
            width: `${comodinInicio1Width}%`,
          }}>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">REINVENTA</h1>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">LO</h1>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">QUE</h1>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">TU</h1>
          </div>
          <div className="comodinFinal1" style={{
            overflow: "hidden",
            height: "100%",
            width: `${comodinFinal1Width}%`,
          }}>
          </div>
        </div>
        <div className='filaTitulo2' style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "50%"
        }}>
          <div className="comodinInicio2" style={{
            overflow: "hidden",
            height: "100%",
            width: `${comodinInicio2Width}%`,
          }}>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">NEGOCIO</h1>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">PODRIA</h1>
          </div>
          <div className='palabra'>
            <h1 className="entrada-izquierda">SER</h1>
          </div>
          <div className="comodinFinal2" style={{
            overflow: "hidden",
            height: "100%",
            width: `${comodinFinal2Width}%`,
          }}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Titulo;
