import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import VentanaConDif from './VentanaConDif';
import VentanaColorEntero from "./VentanaColorEntero";
import VentanaCarrusel from "./VentanaCarrusel"
import "./GridVentanas.css"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './VentanaConDif.css';

import imagen1 from "../Img/pexels-photo-931887.jpeg"
import imagen2 from "../Img/pexels-photo-7078717.jpeg"
import imagen3 from "../Img/teal hammer.jpeg"
import imagen4 from "../Img/pexels-photo-1090977.jpeg"
import imagen5 from "../Img/pexels-photo-8938629.jpg"
import imagen6 from "../Img/pexels-photo-7241432.jpeg"

const MiComponente = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const actualizarWidth = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', actualizarWidth);
        return () => {
            window.removeEventListener('resize', actualizarWidth);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
    };

    return (
        <div className="GridVentanas">
            {windowWidth > 435 && <div className='contenedorGrid'>
                <div style={{
                    maxWidth: '1400px',
                    margin: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: "center",
                }}>
                    <VentanaConDif
                        titulo="Social Trends 2025"
                        subtitulo="ENFOQUE"
                        descripcion="Identifica las tendencias profundizando en la relación socioemocional de los humanos con la tecnología. Lo que necesita saber para afrontar este 2025."
                        url={imagen1}
                        urlBlog="https://peru.landersteams.com/social-trends-2025/"
                        colorLetra="black"
                    />
                    <VentanaColorEntero
                        titulo="IA en Latinoamérica 2025"
                        subtitulo="INFORME"
                        descripcion="El potencial de la Inteligencia Artificial Generativa, sus aplicaciones comerciales y la reingeniería de procesos para potenciar su oferta."
                        urlBlog="https://peru.landersteams.com/ia-en-latinoamerica-2025/"
                        colorLetra="white"
                    />
                    <VentanaConDif
                        titulo="Impacto del CGI en el Branding"
                        subtitulo="ENFOQUE"
                        descripcion="Las empresas logran mayor rendimiento gracias a la tecnología e innovación en la comunicación. El uso de modelación 3D e IA generativa maximiza el atractivo de la oferta."
                        url={imagen2}
                        urlBlog="https://peru.landersteams.com/impacto-del-cgi-en-el-branding/"
                        colorLetra="black"
                    />
                    <VentanaCarrusel
                        titulo="Mezclando átomos y bits en LATAM"
                        subtitulo="ENFOQUE"
                        descripcion="Las tendencias de tecnología 2025, identidad digital, IA generativa, datos y más. Siempre con un objetivo de mejorar la reinvencion y el crecimiento"
                        url={imagen3}
                        urlBlog="https://peru.landersteams.com/mezclando-atomos-y-bits-en-latam/"
                        colorLetra= "black"
                    />
                    <VentanaCarrusel
                        titulo="Pilares del Cambio: Digitalización hacia el Metaverso"
                        subtitulo="ENFOQUE"
                        descripcion="El ritmo de disrupción actual inició un periodo de cambio más competitivo. Para triunfar en este entorno complejo y dinámico, los líderes deberán aprovechar los pilares del cambio."
                        url={imagen4}
                        urlBlog="https://peru.landersteams.com/digitalizacion-hacia-el-metaverso/"
                        colorLetra="black"
                    />
                    <VentanaConDif
                        titulo="Sector micro: Fronteras y mejora de rendimiento"
                        subtitulo="ENFOQUE"
                        descripcion="Qué están haciendo las organizaciones con mayor madurez operativa para crear valor de negocio de una forma más eficiente."
                        url={imagen5}
                        urlBlog="https://peru.landersteams.com/sector-micro-fronteras-y-mejora-de-rendimiento/"
                        colorLetra="black"
                    />
                    <VentanaConDif
                        titulo="Sostenibilidad y transformación en el 2025"
                        subtitulo="ENFOQUE"
                        descripcion="Explora la forma en que los líderes pueden adoptar la sostenibilidad sin destruir el modelo tradicional de negocio."
                        url={imagen6}
                        urlBlog="https://peru.landersteams.com/sostenibilidad-y-transformacion-en-el-2025/"
                        colorLetra="white"
                    />
                    <VentanaColorEntero
                        titulo="Nueva fundación especializada en servicios"
                        subtitulo="ANNOUNCEMENT"
                        descripcion="Un nuevo set de servicios cohesionados ayudará a las empresas a escalar sus esfuerzos a un máximo nivel en lo que conlleva a un modelo de AI generativa conocida como switchboard."
                        colorLetra="white"
                        urlBlog="https://peru.landersteams.com/nueva-fundacion-especializada-en-servicios/"
                    />
                </div>
            </div>}
            {windowWidth < 435 && <div className='contenedorGrid'>
                <Slider {...settings}>
                    <div>
                        <VentanaConDif
                           titulo="Social Trends 2025"
                           subtitulo="ENFOQUE"
                           descripcion="Identifica las tendencias profundizando en la relación socioemocional de los humanos con la tecnología. Lo que necesita saber para afrontar este 2025."
                           url={imagen1}
                           urlBlog="https://peru.landersteams.com/social-trends-2025/"
                           colorLetra="black"/>
                    </div>

                    <div>
                        <VentanaColorEntero
                            titulo="IA en Latinoamérica 2025"
                            subtitulo="INFORME"
                            descripcion="El potencial de la Inteligencia Artificial Generativa, sus aplicaciones comerciales y la reingeniería de procesos para potenciar su oferta."
                            urlBlog="https://peru.landersteams.com/ia-en-latinoamerica-2025/"
                            colorLetra="white"/>
                    </div>

                    <div>
                        <VentanaConDif
                            titulo="Impacto del CGI en el Branding"
                            subtitulo="ENFOQUE"
                            descripcion="Las empresas logran mayor rendimiento gracias a la tecnología e innovación en la comunicación. El uso de modelación 3D e IA generativa maximiza el atractivo de la oferta."
                            url={imagen2}
                            urlBlog="https://peru.landersteams.com/impacto-del-cgi-en-el-branding/"
                            colorLetra="black"
                            />
                    </div>

                    <div>
                        <VentanaCarrusel
                            titulo="Mezclando átomos y bits en LATAM"
                            subtitulo="ENFOQUE"
                            descripcion="Las tendencias de tecnología 2025, identidad digital, IA generativa, datos y más. Siempre con un objetivo de mejorar la reinvencion y el crecimiento"
                            url={imagen3}
                            urlBlog="https://peru.landersteams.com/mezclando-atomos-y-bits-en-latam/"
                            colorLetra= "black"
                        />
                    </div>

                    <div>
                        <VentanaCarrusel
                            titulo="Pilares del Cambio: Digitalización hacia el Metaverso"
                            subtitulo="ENFOQUE"
                            descripcion="El ritmo de disrupción actual inició un periodo de cambio más competitivo. Para triunfar en este entorno complejo y dinámico, los líderes deberán aprovechar los pilares del cambio."
                            url={imagen4}
                            urlBlog="https://peru.landersteams.com/digitalizacion-hacia-el-metaverso/"
                            colorLetra="black"
                        />
                    </div>

                    <div>
                        <VentanaConDif
                            titulo="Sector micro: Fronteras y mejora de rendimiento"
                            subtitulo="ENFOQUE"
                            descripcion="Qué están haciendo las organizaciones con mayor madurez operativa para crear valor de negocio de una forma más eficiente."
                            url={imagen5}
                            urlBlog="https://peru.landersteams.com/sector-micro-fronteras-y-mejora-de-rendimiento/"
                            colorLetra="black"
                        />
                    </div>

                    <div>
                        <VentanaConDif
                             titulo="Sostenibilidad y transformación en el 2025"
                             subtitulo="ENFOQUE"
                             descripcion="Explora la forma en que los líderes pueden adoptar la sostenibilidad sin destruir el modelo tradicional de negocio."
                             url={imagen6}
                             urlBlog="https://peru.landersteams.com/sostenibilidad-y-transformacion-en-el-2025/"
                             colorLetra="white"
                        />
                    </div>

                    <div>
                        <VentanaColorEntero
                            titulo="Nueva fundación especializada en servicios"
                            subtitulo="ANNOUNCEMENT"
                            descripcion="Un nuevo set de servicios cohesionados ayudará a las empresas a escalar sus esfuerzos a un máximo nivel en lo que conlleva a un modelo de AI generativa conocida como switchboard."
                            colorLetra="white"
                            urlBlog="https://peru.landersteams.com/nueva-fundacion-especializada-en-servicios/"
                        />
                    </div>

                </Slider>

                {/*En la vista para celulares 435 x 935, 
                el boton "derecha" de este slider sobresale 
                20px por la derecha de la ventana del navegador
                por eso es que App.js tiene el estilo "overflow: hidden", 
                ya que sino este boton hace que exista un scroll
                horizontal
                */}
            </div>
            }
        </div>
    );
};

export default MiComponente;