import React, { useState } from 'react';
import './Footer.css';

const calculateMovement = (mousePosition, containerWidth, innerDivWidth, movementThreshold) => {
    if (mousePosition < movementThreshold) {
        return -innerDivWidth;
    } else if (mousePosition > containerWidth - movementThreshold) {
        return innerDivWidth;
    } else {
        return 0;
    }
};

const MiComponente = () => {
    const [mousePosition, setMousePosition] = useState(null);

    const handleMouseMove = (event) => {
        setMousePosition(event.nativeEvent.clientX);
    };

    const renderColumn = (width, transitionDuration) => (
        <div className='divTresPalabrasCambio'
            style={{
                width: `${width}px`,
            }}
            onMouseMove={handleMouseMove}
        >
            <div className='palabraCambioUno'
                style={{
                    transform: `translateX(${calculateMovement(
                        mousePosition,
                        width,
                        60,
                        50
                    )}px)`,
                    transition: `transform ${transitionDuration}s ease-out`,
                }}
            >
                <h2 className='footerVertical'>CAMBIO</h2>
            </div>
            <div className='palabraCambioDos'
                style={{
                    transform: `translateX(${calculateMovement(
                        mousePosition,
                        width,
                        60,
                        50
                    )}px)`,
                    transition: `transform ${transitionDuration}s ease-out`,
                }}
            >
                <h2 className='footerVertical'>CAMBIO</h2>
            </div>
            <div className='palabraCambioTres'
                style={{
                    transform: `translateX(${calculateMovement(
                        mousePosition,
                        width,
                        60,
                        50
                    )}px)`,
                    transition: `transform ${transitionDuration}s ease-out`,
                }}
            >
                <h2 className='footerVertical'>CAMBIO</h2>
            </div>
        </div>
    );

    return (
        <div className='Footer'>
            <div className='divPrincipalFooter'>
                <div className='divsVerticales'>
                    <div className='contenedorDivsVerticales'>
                        {renderColumn(
                            window.innerWidth > 1560 ? 45 :
                                window.innerWidth > 1280 ? 25 :
                                    window.innerWidth > 1024 ? 15 :
                                        window.innerWidth > 640 ? 70 :
                                            window.innerWidth > 475 ? 50 :
                                                window.innerWidth > 100 ? 30 : 15,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 85 :
                                window.innerWidth > 1280 ? 65 :
                                    window.innerWidth > 1024 ? 45 :
                                        window.innerWidth > 640 ? 80 :
                                            window.innerWidth > 475 ? 60 :
                                                window.innerWidth > 100 ? 40 : 35,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 150 :
                                window.innerWidth > 1280 ? 130 :
                                    window.innerWidth > 1024 ? 110 :
                                        window.innerWidth > 640 ? 80 :
                                            window.innerWidth > 475 ? 60 :
                                                window.innerWidth > 100 ? 40 : 75,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 180 :
                                window.innerWidth > 1280 ? 160 :
                                    window.innerWidth > 1024 ? 140 :
                                        window.innerWidth > 640 ? 95 :
                                            window.innerWidth > 475 ? 75 :
                                                window.innerWidth > 100 ? 55 : 90,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 130 :
                                window.innerWidth > 1280 ? 110 :
                                    window.innerWidth > 1024 ? 90 :
                                        window.innerWidth > 640 ? 110 :
                                            window.innerWidth > 475 ? 90 :
                                                window.innerWidth > 100 ? 70 : 65,
                            15)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 150 :
                                window.innerWidth > 1280 ? 130 :
                                    window.innerWidth > 1024 ? 110 :
                                        window.innerWidth > 640 ? 95 :
                                            window.innerWidth > 475 ? 75 :
                                                window.innerWidth > 100 ? 55 : 75,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 85 :
                                window.innerWidth > 1280 ? 65 :
                                    window.innerWidth > 1024 ? 45 :
                                        window.innerWidth > 640 ? 80 :
                                            window.innerWidth > 475 ? 60 :
                                                window.innerWidth > 100 ? 40 : 35,
                            7)}
                        {renderColumn(
                            window.innerWidth > 1560 ? 50 :
                                window.innerWidth > 1280 ? 30 :
                                    window.innerWidth > 1024 ? 15 :
                                        window.innerWidth > 640 ? 70 :
                                            window.innerWidth > 475 ? 50 :
                                                window.innerWidth > 100 ? 30 : 15,
                            7)}
                    </div>
                </div>
                <div className='NavFooter'>
                    <div className='divTituloNavFooter'>
                        <h2 className='tituloNavFooter' >
                            Ábrete al cambio
                        </h2>
                    </div>
                    <div className='divBloquesLinksNavFooter'>
                        <div className='BloqueUnoLinksNavFooter'>
                            <a href='https://partners.landersteams.com/buscar-afiliados/' className='LinkNavFooter'>Centro de colaboración</a>
                            <a href='https://partners.landersteams.com/authentication/?step=1' className='LinkNavFooter'>Incorpórate</a>
                            <a href='https://peru.landersteams.com/nosotros/' className='LinkNavFooter'>Quiénes somos</a>
                            <a href='https://https://wa.me/51974810805?text=Hola%20Landers!%20Deseo%20informaci%C3%B3n%20acerca%20de...' className='LinkNavFooter'>Contacto</a>
                        </div>
                        <div className='BloqueDosLinksNavFooter'>
                            <a href='https://peru.landersteams.com/politicas-de-privacidad/' className='LinkNavFooter'>Políticas de Privacidad</a>
                            <a href='https://peru.landersteams.com/terminos-y-condiciones/' className='LinkNavFooter'>Términos y Condiciones</a>
                            <a href="https://peru.landersteams.com/politicas-de-privacidad/" className='LinkNavFooter'>Configuración de Cookies</a>
                            <a href='https://peru.landersteams.com/proyectos-recientes/' className='LinkNavFooter'>Portafolio</a>
                        </div>
                    </div>
                    <div className='divCopyright'>
                        <p className='copyright'>Copyright © 2025 Landers Perú S.A.C</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MiComponente;